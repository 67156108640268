@import './_variables';

.adp-header {
    display: flex;
    flex-flow: row nowrap;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    height: 45px;
    font-size: 14px;
    color: #011f36;
    background: linear-gradient(#ffffff, #f3f3f3);

    > .profile-anchor {
        display: flex;
        margin: 11px 10px 0 10px;
        cursor: pointer;
        background: none;
        border: none;
        padding: 0;
        font-size: 14px;
        font-family: $font-family-default;
        font-weight: $font-weight-default;

        > .avatar {
            width: 16px;
            min-width: 16px;
            height: 16px;
            border-radius: 50%;
            margin-right: 5px;
        }

        > svg {
            margin-right: 5px;
        }

        &:focus {
            color: $primary-color;
        }
    }

    .action-menu {
        margin: 9px 5px 0 5px;

        .dropdown-action-menu {
            button.dropdown-button {
                margin: 0px;
                padding: 0px !important;
                width: 30px !important;
                height: 33px;

                svg {
                    padding: 5px;
                }
            }

            ul.dropdown-items {
                left: 5px;
                top: 35px;
            }
        }
    }

    .user-menu {
        img.avatar {
            display: none;
            width: 20px;
            min-width: 20px;
            height: 20px;
            border-radius: 50%;
            margin: 5px;
        }
    }
}

.sidebar-apps-toggle {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: 15px;
    margin-top: 9px;

    button {
        flex: 1;
        background: none;
        border: none;
        cursor: pointer;
        padding: 0;

        &:first-child {
            margin: 0 0 0 10px;
        }

        &.active-item,
        &:focus {
            color: $primary-color;
            outline: none;
        }

        &:focus {
            background-color: $lightest-gray;
        }

        svg {
            padding: 5px;
            font-size: 20px;
        }
    }

    &-navigation {
        width: 100%;
        height: #{$main-nav-height};
        box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.4);
        margin-right: 0px;
        margin-top: 2px;

        button {
            &:first-child {
                margin: 0 0 0 0px;
            }

            &:focus {
                background-color: inherit;
            }
        }
    }
}
