.grid-share-menu {
  position: relative;
  display: flex;
  align-items: center;
}

.btn-secondary {
  color: #C3C3C3 !important;
}

.grid-share-popup {
  width: 360px;
  padding: 0;
  font-size: 12px;
}

.grid-share-peers-list {
  display: flex;
  align-items: center;
  margin-bottom: 2px;
}

.grid-share-success-popup {
  padding: 10px 0;
  text-align: center;
}

.grid-share-popup-title {
  display: block;
  margin-bottom: 10px;
  font-size: 15px;
  text-align: left;
}

.grid-share-popup-peers {
  margin-bottom: 15px;
  text-align: left;
  width: 100%;
  height: 125px;
  overflow-y: scroll;
  overflow-x: hidden;

  ul {
    padding: 0;
    margin: 0 5px 0 0;

    li {
      display: flex;
      align-items: center;
      font-size: 13px;
      justify-content: space-between;
      width: 100%;

      .peer-info {
        display: flex;
        align-items: center;
        justify-content: flex-start;
      }
    }
  }
}

.grid-share-popup-person-selector {
  height: 90px;
  width: 100%;
  margin-bottom: 10px;
  text-align: left;
}

.grid-share-popup-footer {
  display: flex;
  justify-content: space-between;
}
