.person-selector-input:focus {
  outline: 0;
}

.person-selector-input {
  width: 100%;
  border: none;
  border-bottom: 1px solid rgba(206, 206, 206, 0.6);
  color: #999999;
  background: #FFF;
  font-size: 14px;
}

.person-selector-list-container {
  margin-top: 10px;
  height: 70px;
  width: 100%;
  overflow-y: hidden;
  overflow-x: scroll;
}

.person-selector-people-list {
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
}

.person-selector-people-list-item {
  margin-right: 10px;
  font-size: 10px;
}
