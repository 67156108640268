.left .slider {
  left: 0;
}

.left .slider.open {
  border-right: 1px solid #eaeaea;
}

.right .slider {
  right: 0;
}

.slider {
  > .content {
    height: 100%;
    width: 100%;
  }

  .rocket-chat {
    width: 100%;
    height: 100vh;
  }
}

.e-mobile {
  height: 100%;
  width: 100%;
  overflow: hidden;
}
