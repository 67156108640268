.grid-modal-dialog {
  display: block !important;
  border-radius: 1px;
  position: absolute;
  top: 30px;
  margin: 0;
  padding: 10px;
  height: auto !important;
  overflow: hidden;
  background-color: #FFFFFF;
  color: #666666;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
  z-index: 1;
  transition: 200ms ease;
  visibility: hidden;
  opacity: 0;

  &-enabled {
    visibility: visible;
    opacity: 1;
  }
}
