.profile {
    width: 320px;
    color: $texts-color;

    .profile-parent-avatar-container {
        text-align: center;
        margin: 0px;
        background-image: url("../../images/settings_bg.png");
        background-position: 0px 0px;
        background-size: 100% 113px;
        background-repeat: no-repeat;
    }

    .profile-avatar-container {
        margin-top: 10px;
        position: relative;
    }

    .profile-avatar-image {
        width: 115px;
        height: 115px;
        border-radius: 50%;
        border-width: 4px;
        border-style: solid;
        border-color: white;
        margin-top: 25px;
        cursor: pointer;
        background-color: #ccc;
        display: none;
    }

    .profile-options-panel {
        font-size: 9pt;
        margin: 0px;
        padding: 15px 12px 0px 12px;
        text-align: left;
    }

    .profile-option-icon-container {
        display: inline-block;
        text-align: center;
        width: 81px;
        margin-bottom: 0px;
        padding: 12px 8px;
        cursor: pointer;

        >div {
            margin: 5px 0px;
        }

        >span {
            line-height: 20px;
        }
    }

    .profile-avatar-placeholder {
        width: 115px;
        height: 115px;
        border-radius: 50%;
        border-width: 4px;
        border-style: solid;
        border-color: white;
        margin-top: 25px;
        cursor: pointer;
        background-color: #ccc;
        color: white;
        font-size: 30pt;
        justify-content: center;
        align-items: center;
        display: inline-flex;
    }

    .profile-formatted-name {
        font-size: 11pt;
        padding: 12px;
        animation: fadein 2s;
    }

    .profile-grid-right-bottom {
        border-right: 2px solid #eee;
        border-bottom: 2px solid #eee;
    }

    .profile-user-status-panel {
        margin-bottom: 20px;
        height: 195px;
    }

    .profile-grid-bottom {
        border-bottom: 2px solid #eee;
    }

    .profile-grid-right {
        border-right: 2px solid #eee;
    }

    .profile-list {
        padding: 10px;
        border-bottom: 1px solid #e6e6e6;
    }

    .profile-option-empty {
        height: 32px;
    }

    .profile-link {
        font-size: 12px;
        display: flex;
        align-items: center;
        margin: 15px 0;
        text-decoration: none;

        >svg {
            width: 12px;
            margin-right: 5px;
            fill: #666;
        }
    }

    .profile-content {
        position: relative;
        text-align: left;
        animation: fadein 0.8s;
        font-size: 14px;

        >.title {
            font-size: 18px;
            padding: 15px 10px;
            text-align: center;
        }

        >.profile-content-pane {
            padding: 5px;
        }

        >.title-button {

            position: absolute;
            cursor: pointer;
            position: absolute;
            text-decoration: none;
            font-family: $font-family-default;
            font-size: 20px;
            top: 14px;
        }

        >.close {
            right: 17px;
        }

        >.back {
            top: 12px;
            left: 5px;
        }
    }

    .circle {
        display: inline-block;
        width: 10px;
        height: 10px;
        background: #00cc00;
        border-radius: 50%;
        margin-right: 8px;
    }

    .settings-group-name {
        font-weight: $font-weight-bold;
    }

    .settings-actions {
        margin-top: 20px;

        button {
            float: right;
            font-size: 12px;
            width: auto;
            padding: 9px;
        }
    }
}

@keyframes fadein {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}
