.adp-grid {
  position: relative;
  padding: 2px 10px 0 10px;
  color: $default-text-color;
  font-family: $font-family-default;
}

.grid-overlay-container {
  position: absolute;
  display: flex;
  justify-content: center;
  top: 50%;
  left: 10%;
  right: 10%;
  z-index: 1;
  color: black;
  font-size: 11px;
  background-color: #FFFFFF;

  div {
    padding: 10px;
    border: 1px solid darkgrey;
    border-radius: 10px;
  }
}

.grid-overlay-loader {
  position: absolute;
  display: flex;
  justify-content: center;
  top: 50%;
  left: 10%;
  right: 10%;
  z-index: 1;
  color: black;
  font-size: 11px;
  background-color: transparent;

  div {
    padding: 10px;
  }
}

.ag-theme-fresh {
  font-family: $font-family-default;
  font-weight: $font-weight-default;
  height: calc(100vh - 198px);
  width: 100%;
  font-size: $grid-texts-size;
  color: $grid-texts-color;
  font-style: normal;

  &-with-error {
    height: calc(100vh - 105px);
  }

  .ag-body {
    background-color: #ffffff;
  }

  .ag-root {
    border-color: $grid-detail-color;
    border-bottom-style: none;
  }

  .ag-header {
    border-bottom: none;
    color: $grid-texts-color;
    background: #f7f7f7;
    font-family: $font-family-default;
    font-weight: $font-weight-demi;
  }

  .ag-header-cell {
    border-color: $grid-detail-color !important;
    border-top-style: none;
    background: transparent;
    padding-left: 2px;
    padding-top: 1px;
    padding-bottom: 2px;
  }

  .ag-header-group-cell {
    border-color: $grid-detail-color !important;
    padding-left: 2px;
    padding-top: 1px;
    padding-bottom: 2px;
  }

  .ag-header-icon rect {
    fill: $grid-texts-color;
  }

  .ag-body {
    font-family: $font-family-default;
  }

  .ag-body-viewport {
    background-color: white;
  }

  .ag-cell-numeric {
    text-align: right;
    padding-right: 5px;
  }

  .ag-cell-value {
    border-right: solid 1px $grid-detail-color !important;
    border-right-color: $grid-detail-color !important;
    border-bottom-color: $grid-detail-color;
    border-style: solid;
  }

  .ag-cell-focus {
    outline: none;
  }

  .ag-ltr {
    .ag-has-focus {
      .ag-cell-focus {
        border: none !important;
      }
    }
  }

  .ag-filter {
      .ag-filter-header-container {
          height: 43px;
      }
  }

  .ag-row-odd, .ag-row-even {
    background-color: white;
  }

  .ag-row-focus {
    background-color: #ddd !important;
  }

  .ag-row-selected {
    background-color: #ddd !important;
  }

  .ag-col-selected {
    background-color: #ddd;
  }

  .ag-cell-range-selected {
    background-color: rgba(216, 232, 253, 0.5) !important;
    border-color: #eeeeee !important;
  }

  .ag-cell {
    padding-left: 5px;
  }

  .ag-cell-first-right-pinned {
    border-left: 2px solid #eeeeee !important;
  }

  .ag-cell-last-left-pinned {
    border-right: 2px solid #eeeeee !important;
  }

  .ag-pinned-right-header {
    border-left: 1px solid #eeeeee !important;
  }

  .ag-pinned-left-cols-container {
    background-color: #fafafa;

    .ag-row-even, .ag-row-odd {
      background-color: #fafafa;
    }
  }

  .ag-pinned-right-cols-container {
    background-color: #fafafa;

    .ag-row-even, .ag-row-odd {
      background-color: #fafafa;
    }
  }

  .ag-status-bar {
    border: none;

    [ref="eLabel"] {
      color: #00000099;
    }
  }

  .ag-side-bar {
    border-right: none;

    .ag-tool-panel-wrapper .ag-column-drop .ag-column-drop-empty-message {
      color: rgba(0, 0, 0, 0.6);
    }
  }

  .ag-theme-fresh {
    height: initial;
  }
}

.ag-row:hover {
  background-color: $grid-detail-color;
}

.adp-grid-rownum {
  background-color: $grid-detail-color;
  border-bottom-color: #bbb;
  text-align: center;
}

.adp-grid-header-rownum {
  text-align: center;
  background-color: $grid-detail-color;
}

.ag-theme-fresh .ag-header-group-cell-with-group,
.ag-theme-fresh .ag-header-group-cell {
  border-color: $grid-detail-color;
}

.ag-theme-fresh .ag-header-group-cell-with-group {
  border-bottom-width: 1px;
}

.adp-desktop-grid-link {
  color: #35bbf0;
  text-decoration: none;
}

.ag-bl-center-row {
  min-width: 600px;
}

.ag-header-cell-center-aligned {
  text-align: center;
}

.ag-header-cell-left-aligned {
  text-align: left;
}

.ag-header-cell-right-aligned {
  text-align: right;
}

//.ag-cell > .ag-react-container > div {
//  height: 100%;
//}
//
//.ag-cell > .ag-react-container > div > div {
//  height: 100%;
//}

.ag-cell > .ag-react-container > div > div > div {
  text-overflow: ellipsis;
  overflow: hidden;
  height: 100%;
}

//.ag-cell > .ag-react-container > div > div > div > div {
//  height: 100%;
//}
//
//.ag-cell > .ag-react-container > div > div > div > div > div {
//  height: 100%;
//}

.adp-grid-custom-font-size-compact {
  font-size: 10px;
}

.adp-grid-custom-font-size-normal {
  font-size: 14px;
}

.adp-grid-custom-font-size-large {
  font-size: 20px;
}

.ag-header-cell-text,
.ag-header-group-text {

}
