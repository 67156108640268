.shared-viewpoint {
  .person-avatar-container {
    position: absolute;
    left: 2px;
    top: 12px;
  }

  .link {
    padding-left: 28px !important;
    padding-right: 25px !important;
  }

  .btn-close-perspective {
    margin-left: (-12px) !important;
  }
}
