.rc-menu {
  background-color: white;
  box-shadow: #ccc 1px 1px 3px, #ccc -1px -1px 3px;
  padding: 0;
  width: 200px;
  overflow: hidden;
  margin: 0px;
  border-radius: 0px;

  .rc-menu-item-divider {
    margin: 10px;
  }
}

.adp-rc-menu-item {
  cursor: pointer;
  overflow-x: hidden;
  white-space: normal;
  padding: 7px 16px 7px 16px;
  margin: 0px 1px;
  border-left: solid 3px transparent;
  line-height: 1;

  &:hover {
    color: $dropdown-item-hover-color;
    background-color: $dropdown-item-hover-background-color;
    border-left: solid 3px $primary-color;
  }
}

.rc-menu-item-selected {}

.rc-menu-item-active {
  background-color: white;
}

.rc-menu-item-selected {
  background-color: white;
  font-family: inherit;
  font-weight: inherit;
  overflow-y: auto;
}

.rc-menu-item-group {
  font-size: $grid-texts-size;
}

.rc-menu-item-group-title {
  display: none;
}

.rc-menu-item-group-list {
  padding: 0;
  overflow-x: hidden;
  overflow-y: auto;
}

.rc-menu-item-group-list::-webkit-scrollbar {
  -webkit-appearance: none;
}

.rc-menu-item-group-list::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 5px solid white;
  background-color: rgba(66, 66, 66, 0.5);
}

.rc-menu-item-no-action {
  color: #ccc;
  cursor: default;
}

.rc-menu-item.rc-menu-item-disabled {
  color: #dedede !important;

  &:hover {
    color: #dedede;
    background-color: white;
    border-left: solid 3px transparent;
  }
}
