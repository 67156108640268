.dropdown-grid {
    position: relative;

    &-arrow-down {
        position: absolute;
        bottom: 4px;
        right: 3px;
    }
}

.dropdown-button {
    background-color: transparent;
    border: none;
    color: #666666;
    cursor: pointer;
    height: 28px;
    list-style-type: none;
    outline: none;
    padding: 0px 10px 0px 0px !important;
    width: 35px !important;

    &-disabled {
        color: $dropdown-button-disabled;
        background-color: transparent !important;
    }

    i {
        display: inline-block;
        width: 27px;
    }

    &:hover,
    &:focus {
        background-color: #eee;

        svg {
            color: $primary-color;
        }
    }
}

.dropdown-items {
    background-color: $dropdown-items-back-color;
    border-radius: 1px;
    box-shadow: 0 0 10px 0 $dropdown-items-box-shadow;
    color: $dropdown-items-color;
    cursor: pointer;
    display: block !important;
    height: auto !important;
    min-width: 134px;
    left: -5px;
    list-style-type: none;
    margin: 0;
    opacity: 0;
    overflow: hidden;
    padding: 10px 0;
    position: absolute;
    text-align: left;
    top: 30px;
    transition: 200ms ease;
    visibility: hidden;
    white-space: nowrap;
    z-index: 1;

    &-enabled {
        visibility: visible;
        opacity: 1;
    }

    .dropdown-item {
        font-family: $font-family-default;
        font-size: $font-size-small;
        padding: 5px;
        border: 3px solid transparent;
        background: none;
        outline: none;
        margin: 5px;
        width: 93%;
        text-align: left;

        &:hover,
        &:focus {
            background: $light-gray;
            outline: none;
        }

        svg {
            margin-right: 7px;
            position: relative;
            top: 2px;
        }

        .dropdown-item-label {
            display: inline-block;
            min-width: 70px;
        }

        &-active {

            color: $light-gray;
            border-left: 3px solid $primary-color;
            background-color: $dropdown-item-active-bg-color;

            &:hover {
                color: $dropdown-item-hover-color;
                background-color: $dropdown-item-hover-background-color;
            }
        }
    }

    a.dropdown-item {
        display: block;
        color: $dropdown-items-color;
        text-decoration: none;
        width: calc(100% - 26px);
    }
}
