$desktop-header-height: 60px;
$desktop-footer-height: 60px;
$desktop-title-header: 55px;

.adp-login {
    &,
    * {
        box-sizing: border-box;
    }

    overflow: hidden;
    position: relative;

    &:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        left: 20%;
        z-index: -1;
        background-image: url("data:image/svg+xml,%3Csvg class='chat-logo-background' version='1.2' baseProfile='tiny' id='Layer_1' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 45 49' preserveAspectRatio='xMidYMid meet' style=' width: 60rem%3B max-width: 1140px%3B height: 70rem%3B max-height: 1140px%3B z-index: 1%3B color: %2384a8c8%3B'%3E%3Cpath id='IconChatSolid' fill='currentColor' d='M41.013 34.707c6.984 -9.981 4.556 -23.734 -5.425 -30.719c-9.981 -6.985 -23.734 -4.556 -30.719 5.425c-6.141 8.774 -5.09 20.693 2.491 28.26c2.818 2.988 1.596 5.993 -0 8.013c-0.496 0.642 -0.378 1.565 0.264 2.061c0.275 0.212 0.616 0.32 0.963 0.305c14.48 -0.638 23.582 -3.181 30.494 -11.007c0.691 -0.741 1.932 -2.338 1.932 -2.338'%3E%3C/path%3E%3C/svg%3E");
        background-repeat: no-repeat;
        transform: translate(10%, -2%);
    }

    .adp-login-layover {
        background: $login-background-color;
        height: 100%;
        position: fixed;
        left: 0;
        width: 100%;
        z-index: 20;
        opacity: 0.8;
    }

    .adp-login-body-wrapper {
        height: 100%;
        background: transparent;
        position: relative;
        z-index: 999;
    }
}

.adp-login-header {
    display: flex;
    width: 100%;
    padding: 10px 20px 10px;
    background: $primary-color;
    display: flex;
    align-items: end;
    color: $login-second-color;
    z-index: 10;
    position: relative;
    height: $desktop-header-height;
}

.adp-login-link {
    cursor: pointer;
    text-decoration: none;
    color: $login-font-main-color;
}

.adp-login-body {
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    height: calc(100vh - $desktop-header-height - $desktop-footer-height);
}

.adp-e-auth-container {
    padding: 0 25px;
    color: var(--alias-font-primary);
    display: flex;
    flex-direction: column;
  
    .font-demi {
      font-weight: var(--font-weight-semibold) !important;
    }
  
    .content-section {
      width: 600px;
      margin: 0 auto;
      overflow-y: auto;
      padding: 10% 10px;
  
      .forgot-password-header {
        h3 {
          font-size: 16px;
          font-weight: var(--font-weight-normal) !important;
        }
      }
    }
  
    .action-button svg {
      font-size: 30px;
    }
  
    .form-container {
      padding: 1.25rem 3rem;
      background: var(--component-login-background-card-primary);
      border-radius: 25px;
      -webkit-box-shadow: 0 2px 10px 0 rgb(0 0 0 / 40%);
      -moz-box-shadow: 0 2px 10px 1 rgba(0, 0, 0, 0.4);
      box-shadow: 0 2px 10px 0 rgb(0 0 0 / 40%);
  
      .login-options {
        p {
          margin: 15px 0 0;
        }
        a.link {
          margin: 0 5px;
        }
      }
    }
  
    input {
      background: var(--alias-input-background-primary);
      color: var(--alias-font-primary);
      font-family: var(--font-family-sans);
      padding-left: 10px;
      width: 100%;
      font-size: var(--size-font-base);
      border-radius: 10px;
      outline: none;
      border: 1px solid var(--alias-border-secondary);
      padding-bottom: 0.25rem;
      padding-top: 0.25rem;
      height: 48px;
    }
  
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 30px var(--alias-input-background-primary) inset;
      box-shadow: 0 0 0 30px var(--alias-input-background-primary) inset;
      -webkit-text-fill-color: var(--alias-font-primary);
    }
  
    .action-button {
      color: var(--color-core-neutral-100);
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
      min-height: 50px;
      border: none;
      border-radius: 4px;
      font-family: var(--font-family-sans);
      border: 0;
      background-color: var(--color-core-purple-700);
      cursor: pointer;
       &:disabled{
        opacity: 0.6;
      }
    }
  
    .text-container {
      width: 100%;
      display: flex;
    }
  
    .password-field-container {
      margin: 1.5rem 0 1.5rem 0;
    }
  
    .password-input-container {
      position: relative;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: var(--alias-input-background-primary);
      border: 1px solid var(--alias-border-secondary);
      border-radius: 10px;
  
      input {
        border: 0;
      }
  
      .password-field__button {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: var(--font-family-sans);
        background: none;
        border: 0;
        margin-bottom: 2px;
        color: var(--alias-font-primary);
        font-size: 18px;
        height: 48px;
      }
    }
  
    .link {
      margin: 0 0 1rem 0;
      display: inline-block;
      cursor: pointer;
      color: var(--color-core-blue-700);
    }
  
    .error-text {
      display: block;
      margin-bottom: 1rem;
      margin-top: 0.25rem;
      color: var(--color-core-red-600);
      font-weight: var(--font-weight-semibold);
    }
  
    footer {
      display: flex;
      justify-content: space-between;
  
      .footer-link {
        cursor: pointer;
        text-decoration: none;
        color: var(--color-core-black);
        margin: 5px;
      }
    }
  }

.adp-login-footer {
    display: flex;
    flex-direction: column;
    text-align: left;
    font-weight: var(--font-weight-semibold);
    background: transparent;
    height: $desktop-footer-height;
}

.adp-login-footer-bottom-line {
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
    align-items: flex-end;
    font-family: $font-family-default;
    color: $login-font-main-color;
    font-size: 14px;
    margin: 20px;
}

.adp-login-copyright-link {
    margin-left: 15px;
}

.adp-e-system-status {
    color: $system-up-status-color;
}