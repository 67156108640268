.adp-e-filtering-tree {
	.input-container {
		font-family: $font-family-default;
		display: flex;
		flex-direction: row;
		border: solid 1px #ccc;

		.icon-search {
			padding: 3px;
			margin-top: 5px;
			margin-left: 5px;
		}

		button {
			background: none;
			border: none;
			font-size: 8px;

			// TODO: $gray here is #ccc, so solid color used
			color: #6d6d6d;
			padding: 10px 15px;
		}

		input {
			font-family: $font-family-default;
			font-size: $font-size-small;
			border: none;
			font-size: $font-size-secondary;
			padding: 7px;
			width: 100%;
			margin: 0px;

			&:focus {
				outline: none;
			}
		}
	}

	.tree-container {
		padding: 3px 0px;
		border: solid 1px #ccc;

		ul {
			margin: 0px;
			padding: 0px;
			overflow-y: scroll;
			overflow-x: hidden;

			&::-webkit-scrollbar {
				-webkit-appearance: none;
				width: 7px;
			}

			&::-webkit-scrollbar-thumb {
				border-radius: 1px;
				background-color: rgba(130, 130, 130, 0.5);
			}

			> li {
				cursor: pointer;
				list-style-type: none;
				overflow-x: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;

				button {
					font-family: $font-family-default;
					font-size: $font-size-small;
					padding: 5px;
					margin: 5px;
					background: none;
					border: none;
					border-left: 3px solid transparent;
					width: 97%;
					text-align: left;

					&:hover,
					&:focus {
						background: $light-gray;
						outline: none;
					}

					.highlight {
						font-weight: bold;
					}
				}

				&.selected {
					background-color: $lightest-gray;
					border-left: 3px solid $primary-color;
				}
			}
		}
	}
}
