.ag-grid-cell-comments-header {
  display: flex;
  justify-content: flex-start;

  > button {
    margin-top: 0px;
    margin-bottom: 10px;
  }
}

.ag-grid-cell-comment-menu {
  padding: 12px !important;
  max-height: 250px;
  width: 225px !important;
  overflow-y: auto;
}

.ag-grid-cell-arrow-container {
  position: absolute;
  right: 1px;
  top: 1px;
}

.ag-grid-cell-arrow {
  border-right: 4px solid #F4B400;
  border-top: 4px solid #F4B400;
  border-left: 4px solid transparent;
  border-bottom: 4px solid transparent;
  width: 0px;
  height: 0px;
  cursor: pointer;
  display: block;
}

.ag-grid-cell-popup-arrow {
  position: absolute;
}

.ag-grid-cell-popup-arrow:after,
.ag-grid-cell-popup-arrow:before {
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-width: 10px;
}

.ag-grid-cell-popup-arrow-left {
  left: 12px;
}

.ag-grid-cell-popup-arrow-left:after {
  border-right-color: white;
  margin-left: -30px;
}

.ag-grid-cell-popup-arrow-left:before {
  border-right-color: #ccc;
  margin-left: -32px;
}

.ag-grid-cell-popup-arrow-right {
  left: 279px;
}

.ag-grid-cell-popup-arrow-right:after {
  border-left-color: white;
  margin-left: -30px;
}

.ag-grid-cell-popup-arrow-right:before {
  border-left-color: #ccc;
  margin-left: -29px;
}

.ag-grid-cell-popup-comments-container {
  overflow-y: auto;
  max-height: 155px;
  min-height: 50px;
  padding-right: 10px;
}

.ag-grid-cell-popup-comments-content {
  display: flex;
  margin-top: 10px;
  padding: 5px 5px 10px 5px;
  flex-direction: row;
  white-space: initial;
}

.comments-content-left-pane {
  margin-right: 8px;
}

.comments-content-right-pane {
  width: 100%;
  font-size: 11px;

  > time {
    color: #ccc;
    margin-top: 5px;
    display: block;
    text-align: right;
  }
}

.ag-grid-cell-popup-comments-text {
  color: #666;
  font-size: 12px;
  padding: 0px;
  margin: 0px;
  line-height: 1.3em;
}

.ag-grid-cell-popup-comments-time {
  float: left;
}

.comments-content-edit-controls {
  display: flex;
  justify-content: flex-end;

  > button {
    margin-top: 10px;
  }
}

.ag-grid-cell-popup-comments-divider {
  border: 1px solid #ccc;
  border-bottom-width: 0px;
}

.ag-grid-cell-menu-comment-item {
  padding: 0px !important;
}

.ag-grid-cell-menu-comment-text {
  padding: 5px;
  border: solid 1px #e6e6e6;
  color: #999;
  font-size: 12px;
  width: 100%;
  box-sizing: border-box;
  height: 80px;
  display: block;
}

.ag-grid-cell-menu-comment-text-edit {
  padding: 5px;
  border: solid 1px #e6e6e6;
  color: #999;
  font-size: 12px;
  width: 150px;
  height: 77px;
  display: block;
}

.ag-grid-cell-popup-comments-button-bar {
  height: 15px;
  float: right;
  margin-left: 5px;
  color: #888;

  svg {
    display: inline-block;
  }
}

.ag-grid-cell-menu-comment-btn {
  margin-top: 14px;
  margin-left: 14px;
  background: #ffffff;
  border: none;
  color: #35BBF0;
  cursor: pointer;
  font-size: 12px;
  outline: none;
  padding: 0;
  text-transform: uppercase;
}
