.grid-header {
    border-bottom: 1px solid #eee;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px 0px;
    width: 100%;
    min-height: 45px;

    &-title {
        display: flex;
        align-self: center;

        &-path-container {
            display: flex;
            flex-direction: column;
        }

        &-path {
            margin-bottom: 5px;

            .parent-viewpoint-title {
                align-self: flex-end;
                color: $primary-color;
                cursor: pointer;
                font-size: 12px;
                font-weight: 500;
                white-space: nowrap;
            }

            .nav-sep {
                color: $default-text-color;
                margin: 0px 4px;
            }
        }

        .viewpoint-title {
            font-size: 24px;
            font-weight: 500;
            white-space: nowrap;
            align-self: flex-start;
        }

        &-details {
            display: flex;

            button {
                align-self: flex-end;
                background: none;
                border: none;
                display: inline-block;
                margin-left: 7px;
                padding: 0px;
            }

            svg {
                color: white;
            }

            .favorite {
                svg {
                    color: #F4B400;
                }
            }
        }
    }

    &-actions {
        display: flex;
        align-items: center;

        &-filter {
            margin-left: 10px;
        }
    }
}
