.icon-dropdown-container {
    position: relative;
}

.icon-dropdown-grid {
    font-family: $font-family-default;
    position: relative;
    cursor: pointer;
    display: inline-flex;
    white-space: nowrap;
    width: 180px;
    border-radius: 20px;
    border: 1px solid #ccc;
    background: transparent;
    outline: none;
    padding: 0px;

    &-title {
        font-size: $font-size-smaller;
        color: $gray;
        margin-top: 6px;
        width: 80%;
    }

    &-title-no-icon {
        font-size: 12px;
        color: $gray;
        margin-top: 6px;
        width: 95%;
    }

    &-arrow-down {
        margin-top: 4px;
        margin-right: 4px;
    }

    &--small {
        width: 60px;
        margin: 0 5px;
    }
}

.icon-dropdown {
    &-selected-option {
        background-color: transparent;
        border: none;
        width: 140px;
        font-size: $font-size-small;
        color: $default-text-color;
        margin: 6px 0px 6px 15px;
        text-overflow: ellipsis;
        overflow: hidden;
        display: inline-block;
        text-align: left;

        &-disabled {
            color: $dropdown-button-disabled;
        }
    }
}

.icon-dropdown-items {
    position: absolute;
    left: 0;
    top: 30px;
    width: 100%;
    padding: 10px 0;
    background: $dropdown-items-back-color;
    box-shadow: #ccc 1px 1px 3px, #ccc -1px -1px 3px;
    color: $dropdown-items-color;
    cursor: pointer;
    display: block !important;
    height: auto !important;
    overflow: hidden;
    transition: 200ms ease;
    min-width: 150px;
    z-index: 1;
    white-space: nowrap;
    opacity: 0;
    visibility: hidden;

    &-enabled {
        visibility: visible;
        opacity: 1;
    }
}

.icon-dropdown-item {
    padding: 0;
    height: 25px;
    text-align: left;
    width: 100%;
    display: table;
    border-left: 3px solid transparent;

    &-icon {
        padding: 0 7px 0 0;
        display: table-cell;
        vertical-align: middle;
        text-align: center;
        width: 20px;
    }

    &-label {
        font-family: $font-family-default;
        font-size: $font-size-small;
        padding: 0;
        display: table-cell;
        vertical-align: middle;
        text-align: left;
    }

    button {
        background: none;
        border: none;
        padding: 5px;
        margin: 5px;
        width: 95%;

        &:hover,
        &:focus {
            background: $light-gray;
            outline: none;
        }
    }

    &-active {
        background: $lightest-gray;
        border-left: 3px solid $primary-color;

        // No, I'm not a perfectionist...
        button {
            width: 93%;
        }
    }
}
