.position-node-renderer {
  background-color: transparent;
  text-align: center;
  padding-top: 5px;

  .position-unocuppied-avatar {
    width: 35px;
    height: 35px;
    border: solid 2px $primary-color;
    color: $primary-color;
    display: inline-block;
    border-radius: 20px;
    margin-bottom: 5px;
    background-color: white;

    svg {
      padding: 3px;
    }
  }

  .multiple-associates-avatar {
    .counter {
      width: 35px;
      height: 35px;
      border: solid 2px $primary-color;
      color: $primary-color;
      display: inline-block;
      border-radius: 20px;
      font-size: 20px;
      line-height: 35px;
      background-color: white;
    }

    .names {
      font-weight: $font-weight-bold;
      font-size: 14px;
      margin-top: 5px;
    }
  }

  .position-title {
    font-size: 14px;
  }

  .person-avatar-container {
    .person-avatar-picture {
      position: static;
    }

    .person-avatar-name {
      font-size: 14px;
      padding: 2px 10px 3px 10px;
    }
  }

  .expand-icon {
    color: #999;
  }
}
