.h7 {
  height: 7%;
}

.h10 {
  height: 10%;
}

.h15 {
  height: 15%;
}

.h20 {
  height: 20%;
}

.h30 {
  height: 30%;
}

.h35 {
  height: 35%;
}

.h40 {
  height: 40%;
}

.h50 {
  height: 50%;
}

.h60 {
  height: 60%;
}

.h70 {
  height: 70%;
}

.h80 {
  height: 80%;
}

.h90 {
  height: 90%;
}

.h100 {
  height: 100%;
}
