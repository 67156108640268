.chat-user-account-details {
    text-align: center;

    .avatar-name {
        font-size: $font-size-large;
        width: 100%;
        position: absolute;
        left: 0px;
    }
}
