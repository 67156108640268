$insight-background-color: #EA8126;

.ag-grid-cell-menu-popup-insights {
    .rc-menu-item:hover {
        background-color: transparent;
        border-left: none;
    }
    .ag-grid-cell-popup-arrow-left:after {
        border-right-color: $insight-background-color;
    }
}

.ag-grid-cell-insight-menu {
    padding: 10px !important;
    max-height: 250px;
    width: 225px !important;
    overflow-y: auto;
    background-color: $insight-background-color;
    border: none;
    border-radius: 6px;
}

.ag-grid-cell-popup-insights-content {
    display: flex;
    padding: 5px;
    flex-direction: row;
    white-space: initial;
}

.comments-insight-right-pane {
    color: white;
    font-style: italic;
    font-weight: normal;
    font-size: 10pt;

    p {
        line-height: 16px;
    }

    .insight-description {
        margin-top: 10px;
    }
}
