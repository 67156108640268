.common--inline {
    font-family: $font-family-default;
    font-weight: $font-weight-default;
    font-size: 14px;
    border: 0;
    margin: 2px 0 0;
    width: 95%;

    // Attempting to get DateTimeEditor to show more in 200px width
    letter-spacing: -0.01em;
}
