.grid-compare-cell {
  position: static;
  vertical-align: top;
  display: inline-block;
  padding-right: 12px !important;

  &-negative {
    color: red;
    text-align: right;
  }

  &-positive {
    color: green;
    text-align: right;
  }
}

.grid-diffrow-data {
  background-color: #fbfbec !important;
}

.grid-diffrow-panel {
  background-color: #fbfbec !important;
}
