.adp-desktop-grid-tag {
  background-color: #f5f5f5;
  border-radius: 5px;
  border: 1px solid #cfcfcf;
  color: #666;
  display: inline-block;
  font-size: 11px;
  line-height: 1.4;
  margin: 1px 2px 1px 2px;
  padding: 0 5px;

  &-red {
    background-color: #d9534f;
    border-color: #d43f3a;
    color: #ffffff;
  }

  &-orange {
    background-color: #f0ad4e;
    border-color: #eea236;
    color: #333333;
  }

  &-green {
    background-color: #5cb85c;
    border-color: #4cae4c;
    color: #333333;
  }

  &-blue {
    background-color: #329def;
    border-color: #329def;
    color: #ffffff;
  }
}
