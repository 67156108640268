@import './_variables';

.adp-sidebar {
  transition: all 0.3s ease-out;

  &--right {
    flex: 1 0 320px;
    transition: all 0.3s ease-out;
    // height: calc(100vh - 45px);
    height: 100%;

    .sidebar-item {
      border-left: 1px $light-gray solid;

      .adp-sidebar-section-content {
        overflow: hidden;

        // We should migrate the entirety of timeline to make use of Flex
        &--timeline {
          height: calc(100vh - $header-height);

          &-impersonating {
            height: calc(100vh - $header-height - $impersonating-height);
          }

          &-partner {
            height: calc(100vh - $partner-header-height - $partner-bottom-height);
          }
        }

        &--person-timeline {
          height: calc(100vh - $header-height - $person-header-height);

          &-impersonating {
            height: calc(100vh - $header-height - $person-header-height - $impersonating-height);
          }

          &-partner {
            height: calc(100vh - $person-header-height - $partner-header-height - $partner-bottom-height);
          }
        }

        &--group-timeline {
          height: calc(100vh - $header-height - $people-group-header-height);

          &-impersonating {
            height: calc(100vh - $header-height - $people-group-header-height - $impersonating-height);
          }

          &-partner {
            height: calc(100vh - $people-group-header-height - $partner-header-height - $partner-bottom-height);
          }
        }

        &--group-person-timeline {
          height: calc(100vh - $header-height - $people-group-header-height - $person-header-height);

          &-impersonating {
            height: calc(100vh - $header-height - $people-group-header-height - $person-header-height - $impersonating-height);
          }

          &-partner {
            height: calc(100vh - $people-group-header-height - $person-header-height - $partner-header-height - $partner-bottom-height);
          }
        }
      }
    }

    &-partner-view {
      height: #{$partner-sidebar-content-area};
    }
  }

  &--left {
    flex: 1 0 320px;
    transition: all 0.3s ease-out;
    height: 100%;

    .sidebar-item {
      border-right: 1px $light-gray solid;
    }
  }

  &--hidden--right {
    transform: translateX(100%);
  }

  &--hidden--left {
    transform: translateX(-100%);
  }

  .sidebar-item {
    height: 100%;

    &.sidebar-item-inactive {
      display: block;
      visibility: hidden;
      position: absolute;
      left: -9999px;
    }

    &.sidebar-item-active {
      display: flex;
    }

    &:hover, &.active {
      border-color: $mid-light-gray;
    }
  }
}

.adp-sidebar-section {
  display: flex;
  flex-flow: column nowrap;
  box-sizing: border-box;
  height: 100%;

  .adp-sidebar-person-card-container {
    // Larger margin on top for desktop than mobile
    margin-top: 20px;
    overflow: visible;

    // I can only fathom the reason this has to be different than mobile is due to CSS resets
    .person-card-header {
      height: 65px;
    }
  }
}

.adp-sidebar-section-content {
  display: flex;
  flex-direction: column;
  overflow: scroll;
}

.adp-partner-sidebar-right {
  &-view-container {
    height: 100%;
  }
}

.sidebar-header {
  &-client {
    color: #ffffff;
    background-color: var(--alias-chat-bubble-background-primary);
    display: flex;
    height: #{$header-height};
    width: 100%;
    justify-content: center;
    align-items: center;

    .client-name {
      flex-direction: column;
      justify-content: center;
      text-align: left;
      width: 320px;
      text-transform: uppercase;
      margin: 13px 10px 10px 20px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      line-height: 20px;
      display: flex;
      flex: 1 1 0%;
      align-items: flex-start;
    }

    .close-icon {
      flex: none;
      margin-right: 20px;
      margin-top: 3px;
      border: none;
      padding: 0;
      background: none;
      font-size: min(var(--size-font-base), 32px);
      color: #ffffff;

      &:focus {
        outline: none;
      }
    }
  }

  &-component {
    display: flex;
    grid-area: header;
    justify-content: center;
    align-items: center;
    background-color: var(--alias-tile-background-secondary);
    text-align: center;
    position: relative; // fixes bug with chat bubbles overlaying box-shadow
    z-index: 20;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.4);
    /*padding: 11px 0;*/
    height: $header-height-title-component;

    .page-title-elements {
      flex: 1 1 0%;
      display: flex;
      align-items: center;

      h1 {
        font-weight: var(--alias-tile-font-weight-bold);
        font-size: min($font-size-x-large, 32px);
        text-align: left;
        margin: 0 10px 0 20px;
      }
    }

    .header-icon {
      flex: none;
      margin-right: 20px;
      margin-top: 3px;
      border: none;
      padding: 0;
      background: none;
      font-size: min(var(--size-font-base), 32px);

      &:focus {
        outline: none;
      }
    }

    .header-icon-support-icon.mobile {
      max-width: initial;
      border: 1px solid var(--alias-tile-border-secondary);
      border-radius: 30px;
      padding: 2px 10px;
      margin-top: 0;
      font-weight: $font-weight-medium;
      font-family: $font-family-default;
      font-size: min(var(--size-font-base), 32px);
      display: flex;
      justify-content: center;
      align-items: center;

      &.header-icon-live-chat-active {
        color: var(--alias-font-link-primary);
      }

      span {
        margin-right: 8px;
      }
    }

    .header-icon-live-chat-visible {
      color: var(--alias-tile-font-secondary);
    }

    .header-icon-live-chat-active {
      color: var(--alias-font-danger);
    }

    &.view {
      background-color: var(--color-core-green-600);
      color: var(--alias-tile-font-neutral);
    }

  }

}
