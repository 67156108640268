.spinning-loader {
  margin: 0 auto;
  border: 16px solid #35bbf0;
  border-top: 16px solid transparent;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

.spinning-loader-small {
  border-width: 3px;
  border-top-width: 3px;
  width: 10px;
  height: 10px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
