.button {
    border: none;
    background-color: transparent;
    cursor: pointer;
    padding: 0;
    height: 28px;
    width: 25px;
    overflow: hidden;
    margin-right: 3px;
    color: $gray;

    &:hover,
    &:focus {
        background-color: #eee;
    }

    &:focus {
        outline: 0;
    }

    &-disabled {
        color: $mid-light-gray;
    }

    &-disabled {
        &:hover {
            background-color: transparent !important;
        }
    }

    &-enabled {
        color: #666666;
    }

    &-active {
        color: $primary-color;
    }
}
