@import './_variables';

.adp-sidebar-section {
    .adp-sidebar-section-content {
        .chat-standalone {
            /*height: calc(100vh - 45px);*/
            height: 100%;

            ::-ms-clear {
                display: none;
            }
        }
        .impersonation {
            height: calc(100vh - 90px);
        }
    }
}
.partner-view{
    height: $partner-sidebar-content-area;
  }

  .standard-view{
    height: calc(100vh - 45px);
  }