$main-dark-color: #272d35;
$main-light-color: #454f5e;
$highlight-color: #35bbf0;
$texts-color: #666666;
$texts-color-light-gray: #aaaaaa;
$label-disabled: #cecece;

$login-main-color: #2f3842;
$login-second-color: #ffffff;
$login-background-color: #5eb9ff;
$login-gradient-color: #21272e;
$login-font-main-color: #011f36;
$login-font-main-hover-color: #ffffff;

$system-up-status-color: #0c5603;
$system-down-status-color: #cc0033;

$dropdown-button-enabled: #000000;
$dropdown-button-disabled: #cecece;
$dropdown-items-back-color: #ffffff;
$dropdown-items-box-shadow: rgba(0, 0, 0, 0.2);
$dropdown-items-color: #666666;
$dropdown-item-active-color: #cccccc;

$grid-footer-link: #cccccc;
$grid-detail-color: #eeeeee;
$grid-highlight: #35bbf0;

$primary-color: #483ced;
