.grid-number-cell {
  text-align: right;

  // TODO: fix it!
  padding-right: 12px !important;
}

.ag-react-container {
  height: 100%;
}

.grid-deleted-cell {
  background-color: #ffb6ba;
  margin: -5px;
  padding: 5px;
  height: 17px;
}

.grid-error-cell {
  background-color: #e84c4c !important;
  color: white;
}

.grid-popup-error {
  background-color: #e84c4c !important;
  color: white !important;
  min-height: 25px;
  display: -webkit-box;

  > .ag-grid-cell-menu-arrow-left:after {
    border-right-color: #e84c4c !important;
  }

  > .ag-grid-cell-menu-arrow-right:after {
    border-left-color: #e84c4c !important;
  }

  > .ag-grid-cell-menu-arrow-bottom:after {
    border-top-color: #e84c4c !important;
  }
}

.ag-grid-readonly-header {
  color: #52575c;
}

.ag-cell-edit-input {
  margin-top: -4px;
}

.grid-edited-cell {
  font-weight: bold;
}
