.adp-desktop-grid-table-container {
  display: flex;
  flex-flow: row wrap;
}

.adp-desktop-grid-table-row {
  display: flex;
  flex-flow: nowrap;
  flex-direction: row;
  width: 100%;
}

.adp-desktop-grid-table-cell {
  padding: 5px;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
  flex-wrap: wrap;
  white-space: wrap;
}
