// holds all cell styles referred from meta configuration

.viewpoint-cell-demo-only-red {
    background-color: red !important;
}

.viewpoint-cell-demo-only-green {
    background-color: green;
}

.viewpoint-cell-text-eblue {
    color: $primary-color
}

.viewpoint-cell-text-transparent {
    color: transparent;
}

.row-details-popup {
    max-height: 50vh;
    width: 100%;
    overflow-y: scroll;
    background-color: white;
    border-radius: 14px;
}

.pay-statement-currency-cell {
    width: 100%;
    margin-right: 2px;
}

.viewpoint-cell-readonly {
    color: grey;
}

.viewpoint-cell-bg-color-green {
    background-color: #84EC7D;
}

.viewpoint-cell-bg-color-yellow {
    background-color: #F0EC70;
}

.viewpoint-cell-bg-color-blue {
    background-color: #6699CC;
}

.viewpoint-cell-bg-color-cream {
    background-color: #FFFDD0;
}

.viewpoint-cell-bg-color-red {
    background-color: #EB7062;
}

.viewpoint-cell-bg-color-orange {
    background-color: $insight-text-color;
}

.viewpoint-cell-parent-row {
    font-weight: bolder;
    border-bottom-color: #ffffff !important;
    border-top-color: #cccccc !important;
}

.viewpoint-cell-child-row {
    border: none;
    border-bottom-color: #ffffff !important;
}

.viewpoint-cell-bg-text-color {
    color: #ffffff;
    background-color: #EB7062;
}
