.adp-desktop-grid-avatar {
    display: flex;

    &-none {
        margin: auto;
        height: 20px;
        width: 20px;
        border-radius: 50%;
        background: #EF4548;
    }

    &-name {

    }

    &-singleline {
        align-items: baseline;
    }

    &-labels {
        margin-left: 5px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }

    &-row-top {
        line-height: 10px;
        margin-top: 1px;
    }

    &-row-bottom {
        font-size: 10px;
        margin-top: 2px;
        line-height: 10px;
        color: #002342;
    }
}
