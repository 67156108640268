.btn-pin-filter {
  border: solid 1px #e6e6e6;
  height: 29px;
  position: relative;
  top: 2px;
  color: #999;

  &-active {
    background-color: #eee;
    border-color: #ccc;
    color: black;
  }

  &-inactive {}

  > svg {
    position: relative;
    top: 2px;
  }
}
