.option-font-compact {
    font-size: 14px !important;
}

.option-font-normal {
    font-size: 18px !important;
}

.option-font-large {
    font-size: 21px !important;
}
