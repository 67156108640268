$desktop-header-height: 60px;
$desktop-footer-height: 60px;
$desktop-title-header: 55px;

.adp-login {
    &,
    * {
        box-sizing: border-box;
    }

    overflow: hidden;
    position: relative;

    &:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        left: 20%;
        z-index: -1;
        background-image: url("data:image/svg+xml,%3Csvg class='chat-logo-background' version='1.2' baseProfile='tiny' id='Layer_1' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 45 49' preserveAspectRatio='xMidYMid meet' style=' width: 60rem%3B max-width: 1140px%3B height: 70rem%3B max-height: 1140px%3B z-index: 1%3B color: %2384a8c8%3B'%3E%3Cpath id='IconChatSolid' fill='currentColor' d='M41.013 34.707c6.984 -9.981 4.556 -23.734 -5.425 -30.719c-9.981 -6.985 -23.734 -4.556 -30.719 5.425c-6.141 8.774 -5.09 20.693 2.491 28.26c2.818 2.988 1.596 5.993 -0 8.013c-0.496 0.642 -0.378 1.565 0.264 2.061c0.275 0.212 0.616 0.32 0.963 0.305c14.48 -0.638 23.582 -3.181 30.494 -11.007c0.691 -0.741 1.932 -2.338 1.932 -2.338'%3E%3C/path%3E%3C/svg%3E");
        background-repeat: no-repeat;
        transform: translate(10%, -2%);
    }

    .adp-login-layover {
        background: $login-background-color;
        height: 100%;
        position: fixed;
        left: 0;
        width: 100%;
        z-index: 20;
        opacity: 0.8;
    }

    .adp-login-body-wrapper {
        height: 100%;
        background: transparent;
        position: relative;
        z-index: 999;
    }
}

.adp-login-header {
    display: flex;
    width: 100%;
    padding: 10px 20px 10px;
    background: $primary-color;
    display: flex;
    align-items: end;
    color: $login-second-color;
    z-index: 10;
    position: relative;
    height: $desktop-header-height;
}

.adp-login-link {
    cursor: pointer;
    text-decoration: none;
    color: $login-font-main-color;
}

.adp-login-body {
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    height: calc(100vh - $desktop-header-height - $desktop-footer-height);
}

.adp-login-message {
    flex: 2;
    color: $login-main-color;
    text-align: right;
    margin-right: 30px;
    align-self: flex-start;
}

.adp-e-welcome-wrapper {
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    padding: 35px 10px;
    background: $login-second-color;
    border-radius: 15px;
    -webkit-box-shadow: 0 2px 10px 0 rgb(0 0 0 / 40%);
    -moz-box-shadow: 0 2px 10px 1 rgba(0, 0, 0, 0.4);
    box-shadow: 0 2px 10px 0 rgb(0 0 0 / 40%);
}

.adp-login-welcome-message {
    font-weight: $font-weight-demi;
    font-size: 34px;
    margin-bottom: 20px;
}

.adp-login-instructions-message {
    font-family: $font-family-default;
    font-size: 16px;
    line-height: 24px;

    .adp-login-instructions-highlight {
        color: #329def;
    }

    button {
        background: none;
        border: none;
    }
}

.adp-login-qrcode,
.adp-login-retry-container,
.adp-login-qrcode-placeholder {
    flex: 1.5;
    text-align: left;
}

.adp-login-qrcode-placeholder {
    width: 150px;
    height: 150px;
}

.adp-login-retry-container {
    position: relative;
}

.adp-login-retry-qrcode {
    opacity: 0.03;
}

.adp-login-retry-message {
    position: absolute;
    top: 0;
    left: 0;
    width: 150px;
    height: 150px;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: $login-main-color;
    font-size: 19px;
}

.adp-login-retry-link {
    cursor: pointer;
    color: $highlight-color;
    &::before,
    &::after {
        content: " ";
    }
}

.adp-login-footer {
    display: flex;
    flex-direction: column;
    text-align: left;
    font-weight: var(--font-weight-semibold);
    background: transparent;
    height: $desktop-footer-height;
}

.adp-login-instructions {
    width: 345px;
    margin: 0 auto 0;
    text-align: justify;
    font-size: 12px;
    line-height: 1.3;
    color: $login-second-color;
}

.adp-login-footer-bottom-line {
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
    align-items: flex-end;
    font-family: $font-family-default;
    color: $login-font-main-color;
    font-size: 14px;
    margin: 20px;
}

.adp-login-copyright-link {
    margin-left: 15px;
}

.adp-e-system-status {
    color: $system-up-status-color;
}
