.grid-harmonized-header {
  background: rgba($grid-harmonization-parent-color, 1) !important;
  border: none !important;
}

.grid-harmonized-cell {
  background: rgba($grid-harmonization-parent-color, 0.9) !important;
  border: none !important;
  border-bottom: 1px solid rgba($grid-harmonization-border-color, 0.4) !important;
}

.grid-harmonization-header {
  border: none !important;
  background: rgba($grid-harmonization-color, 1) !important;
}

.grid-harmonization-cell {
  border: none !important;
  border-bottom: 1px solid rgba($grid-harmonization-border-color, 0.4) !important;
  background: rgba($grid-harmonization-color, 0.9) !important;
}
