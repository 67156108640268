.adp-e-horizontal-slider {
	align-items: center;
	display: flex;
	white-space: nowrap;

	&-content {
		overflow: hidden;
	}

	&-scroll-node {
		overflow-x: scroll;
		overflow-y: hidden;
		position: relative;
		-ms-overflow-style: none;

		&::-webkit-scrollbar {
			display: none;
		}
	}

	&-controls {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-left: 10px;
		border-left: solid 2px $neutral-400;

		button {
			display: flex;
			align-items: center;
			justify-content: center;
			background: none;
			border: none;
			padding: 0;
			cursor: pointer;

			&:first-of-type {
				margin-left: 3px;
			}

			&:hover,
			&:focus {
				background-color: $neutral-800;
				outline: none;

				svg {
					color: $neutral-100;
				}
			}

			svg {
				padding: 5px;
				color: $neutral-900;
			}
		}
	}
}
