.grid-chart-container {
  overflow: hidden;
  height: calc(100vh - 265px);
}

.adp-ag-grid-chart-container {
    display: flex;
    justify-content: center;
    border-top: solid 1px #eee;
    height: calc(100vh - 310px);

    .hidden-grid {
        position: absolute;
        left: -2000px;
        width: 1000px;
        height: 1000px;
        border: dashed 1px red;
    }

    .ag-chart-canvas-wrapper {
        margin-top: 75px;
    }

    .ag-chart-tabbed-menu {
        border-right: solid 1px #BDC3C7;
        border-bottom: solid 1px #BDC3C7;
    }

    .ag-chart-menu {
        visibility: hidden;
    }

    .chart-component-node {
        width: 100%;
    }
}

.grid-map-chart-container,
.grid-treemap-chart-container {
  position: relative;
  top: 10%;
  height: 80%;
  width: 70%;
  left: 15%;
}

.highcharts-credits {
  display: none;
}

.tree-container {
  overflow: auto;
  border-top: solid 1px #eee;

  &-canvas {
    width: 5000px;
    height: 5000px;
  }
}
