.grid-quick-filter {
    position: relative;
    width: 270px;
    padding: 5px;
    border-radius: 20px;
    font-family: $font-family-default;
    border: solid 1px #ccc;

    input {
        font-family: $font-family-default;
        font-size: $font-size-small;
        border: none;
        width: 230px;
        margin-left: 10px;
        background-color: transparent;

        &:focus {
            outline: none;
        }
    }

    &-clear {
        display: inline-block;

        .icon-search {
            position: relative;
            top: 2px;
        }

        button {
            position: absolute;
            right: 7px;
            top: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            border: none;
            background: none;
            padding: 10px;
        }
    }
}
