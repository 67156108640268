.ag-grid-custom-title-popup {
  position: absolute;
  display: inline-block;
  background: white;
  border: 1px solid #d9d9d9;
  box-shadow: #ccc 1px 1px 3px, #ccc -1px -1px 3px;
  padding: 5px;
  font-family: $font-family-default;
  color: #666666;
  font-size: 12px;
  z-index: 10000;
}

.ag-grid-custom-title-popup-fade-in {
  animation: fadein 0.3s;
}
