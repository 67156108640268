.desktop-modal-background {
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 1;
  transition: 200ms ease;
}

.desktop-modal-content {
  width: 300px;
  height: auto !important;
  margin: 200px auto;
  padding: 20px 10px;
  border-radius: 1px;
  overflow: hidden;
  background-color: #FFFFFF;
  color: #666666;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
  z-index: 2;
  text-align: center;
  font-size: 12px;
}

.desktop-modal-btn {
  margin-top: 10px;
  background: #ffffff;
  border: none;
  color: #53AFED;
  cursor: pointer;
  font-size: 11px;

  outline: none;
  padding: 0;
  text-transform: uppercase;
}

.desktop-modal-btn-secondary {
  color: #C3C3C3 !important;
}
