.grid-projection-main-column {
  background: rgba($grid-projection-parent-color, 1) !important;
  border: none !important;
}

.grid-projection-main-cell {
  background: rgba($grid-projection-parent-color, 0.9) !important;
  border: none !important;
  border-bottom: 1px solid rgba($grid-projection-parent-color, 1) !important;
  padding: 2px 5px !important;
  text-align: right !important;
}

.grid-projection-column-0,
.grid-projection-column-1,
.grid-projection-cell-0,
.grid-projection-cell-1 {
  border: none !important;
}

.grid-projection-column-0 {
  background: rgba($grid-projection-0-color, 1) !important;
  padding: 0 2px !important;
  height: 100% !important;
}

.grid-projection-cell-0 {
  background: rgba($grid-projection-0-color, 0.9) !important;
  padding: 2px 5px !important;
  border-bottom: 1px solid rgba($grid-projection-0-color, 1) !important;
  text-align: right !important;
}

.grid-projection-column-1 {
  background: rgba($grid-projection-1-color, 1) !important;
  padding: 0 2px !important;
  height: 100% !important;
}

.grid-projection-cell-1 {
  background: rgba($grid-projection-1-color, 0.9) !important;
  padding: 2px 5px !important;
  border-bottom: 1px solid rgba($grid-projection-1-color, 1) !important;
  text-align: right !important;
}

.ag-grid-projection-column-label {
  display: inline;
  cursor: auto;
  width: 40px;
  font-size: 12px;
  text-align: left;
}

.ag-grid-projection-column-input {
  display: inline-block;
  width: 40px;
  height: 12px;
  margin: 0 3px;
  padding: 2px;
  font-size: 12px;
}

.ag-grid-projection-column-display-name {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  width: calc(100% - 80px);
  position: relative;
  top: 3px;
}
