$bar-color: #99CC99;

.adp-desktop-grid-horizontal-bars-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 80%;
}

.adp-desktop-grid-horizontal-bars-left {
  background-color: lightcoral;

  min-width: 10%;
  text-align: center;
}

.adp-desktop-grid-horizontal-bars-right {
  background-color: $bar-color;

  min-width: 10%;
  text-align: center;
}

.adp-desktop-grid-horizontal-bar-container {
  border: solid 1px $bar-color;
  width: 80%;
}

.adp-desktop-grid-horizontal-bar {
  background-color: $bar-color;


  &-text {
    padding-left: 5px;
  }
}
