body {
  font-family: $font-family-default;
  font-weight: $font-weight-default;
  overflow: hidden;
}

h1 {
  font-size: 24px;
}

.adp-main {
  transition: all 0.3s ease-out;
  width: 100%;
  overflow: hidden;

  // Right sidebar resizable, this is default value also via actions/sidebar.js
  margin-right: -320px;

  // Left sidebar default size
  margin-left: -320px;
}
