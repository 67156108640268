.position-details-renderer {
  display: flex;
  flex-direction: column;
  line-height: initial;
  padding: 10px 10px 0px 4px;

  .associate-panel {
    display: flex;
    margin-bottom: 10px;

    .person-avatar-container {
      margin: 0px 10px;
    }

    .person-card-icon {
      border: solid 1px #ccc;
      border-radius: 25px;
      width: 40px;
      height: 40px;
      text-align: center;
      line-height: 53px;
    }

    .buttons-panel {
      padding-right: 12px;

      a {
        color: $primary-color;
        text-decoration: none;
      }
    }

    .details-panel {
      white-space: normal;
      font-size: 12px;
      width: 100%;

      .associate-name {
        font-weight: bold;
      }

      a {
        color: $primary-color;
        text-decoration: none;
        font-weight: 500;
        cursor: pointer;
      }
    }
  }
}

.adp-grid .person-avatar-name {
  color: #00080F;
}
