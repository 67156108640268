.multi-select {
    border-radius: 20px;
    border: 1px solid #ccc;
    cursor: pointer;
    padding: 0px 6px;
    min-height: 32px !important;
    height: 32px;
    &-placeholder {
        font-family: $font-family-default;
        font-size: $font-size-small;
        color: $default-text-color;
        font-weight: 400;
    }
    &-dropdown-indicator{
        margin-right: 4px;
    }
    &-menu {
        background-color: $dropdown-items-back-color;
        border-radius: 1px;
        box-shadow: 0 0 10px 0 $dropdown-items-box-shadow;
        color: $dropdown-items-color;
        cursor: pointer;
        margin: 0;
        padding: 10px 0;
        z-index: 3 !important;
    }

    &-option {
        display: flex !important;
        padding: 5px 0px;
        align-items: center;
        &:hover {
            color: $dropdown-item-hover-color;
            background-color: $dropdown-item-hover-background-color;
        }
        &-checkbox {
            position: absolute;
            top: 0;
            left: 0;
            margin: 0;
            padding: 0;
            opacity: 0;
            z-index: 1;
            cursor: inherit;
            box-shadow: none;
            outline: none;
        }
        &-checkmark-container {
            display: flex;
			width: 35px;
			justify-content: center;
        }
        .checkmark {
            height: 14px;
            width: 14px;
            border: 2px solid var(--component-selectlist-checkmark-border-secondary);
            transition: background-color 90ms cubic-bezier(0, 0, 0.2, 0.1),
                display 90ms cubic-bezier(0, 0, 0.2, 0.1);
            border-radius: 4px;

            // checkmark
            &::after {
                content: '';
                position: relative;
                display: none;
                left: 3px;
                top: 0;
                width: 4px;
                height: 8px;
                border: solid var(--component-selectlist-checkmark-border-primary);
                border-width: 0 3px 3px 0;
                -webkit-transform: rotate(45deg);
                -ms-transform: rotate(45deg);
                transform: rotate(45deg);
                box-sizing: content-box;
            }

            &.checked {
                border-color: var(--component-selectlist-checkmark-border-primary);
                &::after {
                    display: block;
                }
            }
        }
        .label {
            font-family: $font-family-default;
            font-size: $font-size-small;
            color: var(--alias-font-primary);

            &.selected{
                color: var(--component-selectlist-font-secondary)
            }
        }
    }

    &-tag {
        border-radius: 20px;
        background-color: #eeeeee;
        padding: 4px 8px;
        margin-right: 4px;
    }

    &-indicator-separator {
        width: 1px;
        margin-bottom: 2px;
        margin-top: 2px;
        background-color: #ccc;
        align-self: stretch;
        margin: 0px 8px;
    }
}