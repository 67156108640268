body {
  font-family: $font-family-default;
  // taken from https://stackoverflow.com/questions/15829172/stop-chrome-back-forward-two-finger-swipe
  overscroll-behavior-x: none;
}

@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}
