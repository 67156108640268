.grid-footer {
    display: flex;

    /* TODO: remove this rule, adjust the css bg color of footer and grid borders */
    border-top: solid 1px #eee;
    width: 100%;
    background: linear-gradient(#ffffff, #f3f3f3);
    height: 50px;
    flex-flow: row nowrap;

    a {
        &:visited {
            color: $default-text-color;
        }
    }

    .adp-e-horizontal-slider {
        align-items: stretch;
        overflow: hidden;

        &-controls {
            height: 30px;

            svg {
                margin-top: 7px;
            }
        }
    }
}

.grid-footer-viewpoint-link {
    font-size: 14px;
    color: $default-text-color;
    height: 35px;
    line-height: 30px;
    padding: 0 10px;
    margin: 0 5px 5px 5px;
    display: inline-block;

    &-in-progress {
        color: #BFC48B;
    }

    &-deprecated {
        color: #c48b8b;
    }

    &-active {
        background-color: #ffffff;
        border-radius: 0 0 4px 4px;
        border-top: none;
        box-shadow: 2px 0 3px #AAA, -2px 0 3px #AAA;
    }

    a {
        color: $default-text-color;
        text-decoration: none !important;
    }

    a.active {
        color: $primary-color !important;
    }
}

.grid-footer-viewpoint-slider {
    flex: 6 1;
}

.grid-footer-status-panel {
    flex: 1 0;
    font-size: 14px;
    color: #011f36;
    margin-top: 15px;
    margin-left: auto;
    margin-right: 20px;
    text-align: right;
    white-space: nowrap;
}

.grid-footer-last-update-time {
    color: #999999;
    margin: 5px 0 2px 8px;
    font-size: 12px;
}

.grid-footer-filtering-list {
    max-width: 20px;
    flex: 1;
    padding: 10px 5px;
    cursor: pointer;
    margin-left: 60px;

    button {
        background: none;
        border: none;
    }

    .input-container {
        border-bottom: none;

        ::placeholder {
            color: #ccc;
        }

        input {
            font-size: 12px !important;
            color: $texts-color;
            margin-top: 3px !important;
        }
    }

    &-container {
        animation: fadein 0.5s;
        background-color: white;
        bottom: 60px;
        box-shadow: #ccc 1px 1px 3px, #ccc -1px -1px 3px;
        color: $texts-color;
        font-size: $grid-footer-size;
        left: 58px;
        position: absolute;
        width: 300px;
        z-index: 3;

        &.hidden {
            display: none;
        }

        .arrow-down {

            &::after,
            &::before {
                border: solid 10px transparent;
                bottom: -18px;
                content: " ";
                height: 0;
                left: 5px;
                width: 0;
                position: absolute;
                pointer-events: none;
            }

            &::before {
                border-top-color: #ccc;
                border-width: 10px;
                bottom: -20px;
            }

            &::after {
                border-top-color: white;
                border-width: 10px;
            }
        }

        .adp-e-filtering-select .list-container {
            color: #35BBF0;
            border-top: 0;
        }
    }
}
