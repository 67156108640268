.adp-e-filtering-select {
	position: relative;
	.input-container {
		display: flex;
		flex-direction: row;
		border: solid 1px #ccc;
		.icon-search {
			padding: 3px;
			margin-top: 6px;
			margin-right: 10px;
		}
		.icon-reset {
			padding: 3px;
			margin-top: 8px;
			margin-right: 10px;
		}
		input {
			border: none;
			font-size: $font-size-secondary;
			padding: 7px;
			width: 100%;
			margin: 0px;
			&:focus {
				outline: none;
			}
		}
	}
	.list-container {
		position: absolute;
		z-index: 2;
		padding: 3px 0px;
		border: solid 1px #ccc;
		background-color: white;
		width: 100%;
		box-sizing: border-box;
		ul {
			margin: 0px;
			padding: 0px;
			overflow-y: scroll;
			overflow-x: hidden;
			&::-webkit-scrollbar {
				-webkit-appearance: none;
				width: 7px;
			}
			&::-webkit-scrollbar-thumb {
				border-radius: 1px;
				background-color: rgba(130, 130, 130, 0.5);
			}
			> li {
				cursor: pointer;
				list-style-type: none;
				padding: 5px 10px;
				margin: 0px 0px 0px 6px;
				overflow-x: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
				&.separator {
					border-top: solid 1px #ccc;
					line-height: 1px;
					font-size: 1pt;
					padding: 0px;
				}
				&.selected {
					background-color: #eee;
				}
				&:hover {
					background-color: #ccc;
				}
				.highlight {
					font-weight: bold;
				}
			}
		}
	}
}
