.adp-empty-perspectives-list {
  position: relative;
  top: 50px;
  left: 50px;
  background-image: url("../../images/sad_grumpy.jpeg");
  background-repeat: no-repeat;
  padding-left: 170px;
  height: 300px;
  border-width: 1px;
  width: 400px;
  padding-right: 80px;
  background-position: 35px 13px;
  border-radius: 30px;
  background-size: 100px;

  &-content {
    position: relative;
    top: 55px;
  }

  &-main-message {
    display: block;
    margin-bottom: 5px;
  }

  &-tip-label {

    font-style: italic;
    font-size: 14px;
    margin-right: 5px;
  }

  &-tip {
    font-size: 14px;
  }
}
