$perspective-link-color: #35bbf0;
$perspective-link-size: 14px;
$perspective-link-hover-color: #ffffff;

$main-nav-height: 55px;
$header-height: 45px;
$header-height-title-component: 55px;
$impersonating-height: 46px;
$partner-bottom-height: 57px;
$partner-header-height: 100px;
$people-group-header-height: 116px;
$person-header-height: 98px;  //

$partner-sidebar-content-area: calc(
    100vh - #{$main-nav-height} - #{$header-height} - #{$header-height-title-component} - env(safe-area-inset-bottom, 0px)
);
