.ag-popup-editor {
  z-index: 999;
}

.viewpoint-cell-editor-input {
  min-height: 22px;
  margin-left: 0.5px;
  font-size: 11px;
  margin-top: 3px;
  border: 0px;
  text-indent: 5px;
  width: 98%;

  &:focus {
    outline: none;
  }
}

.viewpoint-cell-editor-dropdown {
  width: 99%;
  background-color: white;
  border: 1px solid #e6e6e6;
  min-height: 50px;
  z-index: 999;
  margin-top: 2px;

    >div>div {
        position: initial !important;
    }
}

.viewpoint-cell-editor-dropdown-item {
  padding: 3px;
  margin-top: 8px;
  margin-bottom: 8px;
  margin-left: 8px;
  cursor: pointer;

  &:hover {
    color: #35bbf0;
  }
}

.viewpoint-cell-editor-dropdown-selected-icon {
  margin-right: 5px;
}
