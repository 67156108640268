$grid-texts-color: $default-text-color;
$grid-highlight: #35bbf0;
$grid-detail-color: #eeeeee;
$grid-text-disabled: #b1b1b1;

$dropdown-button-color: #000000;
$dropdown-button-disabled: #cecece;
$dropdown-items-back-color: #ffffff;
$dropdown-items-box-shadow: rgba(0, 0, 0, 0.2);
$dropdown-items-color: #666666;
$dropdown-item-selected-color: #329def;
$dropdown-item-active-color: #cccccc;
$dropdown-item-active-bg-color: $lightest-gray;
$dropdown-item-hover-color: $primary-color;
$dropdown-item-hover-background-color: #dedede;

$grid-history-icon: #3399ff;
$grid-history-border-color: #a4bad6;
$grid-history-parent-color: #b4d4fc;
$grid-historical-0-color: #c0dbfc;
$grid-historical-1-color: #cde2fd;
$grid-historical-2-color: #d9e9fd;

$grid-projection-icon: #f86f13;
$grid-projection-parent-color: #ffaf7a;
$grid-projection-0-color: #ffc6a0;
$grid-projection-1-color: #ffddc6;

$grid-harmonization-icon: #00cc66;
$grid-harmonization-border-color: #abdaac;
$grid-harmonization-parent-color: #b7f8b8;
$grid-harmonization-color: #cafacc;

$input-gradient-top: #eaeaea;
$input-gradient-bottom: #fafafa;
