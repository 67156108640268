.renderer-container {
  height: 100%;
}

.renderer {
  align-items: center;
  display: flex;
  height: 100%;
  margin: 0 2px;
  white-space: nowrap;
}

.text-renderer {
  align-items: center;
  text-overflow: ellipsis;
  overflow: hidden;
  height: 100%;
  margin: 0 2px;
  white-space: nowrap;
  width: 100%;
}

.left-renderer {
  justify-content: flex-start;
}

.center-renderer {
  justify-content: center;
}

.right-renderer {
  justify-content: flex-end;
  padding-right: 2px;
  width: 100%;
}

.composite-renderer {
  width: 100%;
}

.highlighted-renderer {

}

.job-posting-renderer {
  width: 20px;
  height: 20px;
}

.custom-tooltip {
    background: white;
    border: 1px solid #d9d9d9;
    box-shadow: #ccc 1px 1px 3px, #ccc -1px -1px 3px;
    color: #666666;
    font-family: $font-family-default;
    font-size: 12px;
    height: auto;
    min-height: 45px;
    min-width: 120px;
    pointer-events: none;
    position: absolute;
    transition: opacity 1s;
    width: auto;
    z-index: 10000;

    .ag-tooltip-hiding {
        opacity: 0;
    }

    .custom-tooltip-text {
        padding: 5px;
    }
}
