// Import from @adplabs/adp-e-common/common/styles/index.scss
@import "~@adplabs/adp-e-common/common/styles/index.scss";

@import "_variables";
@import "_sharedViewpoints";

.adp-links {
    font-family: $font-family-default;
    font-size: 14px;
    color: #011f36;
    -webkit-user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -ms-user-select: none;
    display: grid;

    .buttons-container {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;

        polyline {
            stroke-width: 10px;
        }

        .button-container {
            margin-left: 10px;
            margin-right: 10px;
            cursor: pointer;
        }
    }

    .single-button-container {
        margin-left: 10px;
        margin-top: 9px;

        &.active {
            background-color: white;
        }

        a {
            display: flex;
            cursor: pointer;
            color: $gray;

            &:hover,
            &:focus {
                background-color: $lightest-gray;
                outline: none;
            }

            svg {
                padding: 5px;
            }
        }
    }

    .container {
        display: flex;
        flex-direction: row;
        white-space: nowrap;
        overflow: hidden;

        .items {
            white-space: nowrap;
            overflow: hidden;

            &-favorites {
                white-space: nowrap;
                float: left;
            }

            .item-slider {
                height: 45px;
                transition: transform 1s ease-in;

                .adp-e-horizontal-slider-scroll-node {
                    height: 45px;
                }

                // may be removed once we change the perspective menu to emulate browser tabs
                .adp-e-horizontal-slider-controls {
                    margin-top: 9px;
                }
            }

            .item {
                display: inline-block;
                position: relative;
                top: 8px;
                text-align: center;
                margin: 3px 0 0 0;

                &.active {
                    background-color: #ffffff;
                    border-radius: 4px;
                    box-shadow: 0 -1px 5px #CCC;

                    path {
                        stroke: #272d35;
                    }
                }

                &-transition-leave {
                    opacity: 1;
                    transition: opacity 1s ease-in;

                    &.item-transition-leave-active {
                        opacity: 0.01;
                        transition: opacity 250ms ease-in;
                    }
                }

                &-standard + .item-standard:not(.active):before {
                    content: "";
                    position: absolute;
                    height: 60%;
                    left: 0;
                    top: 25%;
                }

                &-pin {
                    padding-right: 0px;

                    &:last-of-type {
                        padding-right: 10px;
                    }

                    &:not(.active) + .item-pin:not(.active):before {
                        content: "";
                        position: absolute;
                        height: 60%;
                        left: 0;
                        top: 25%;
                    }
                }

                span {
                    position: absolute;
                    top: 9px;
                    right: 9px;
                    display: none;
                }

                > .link {
                    text-decoration: none;
                    font-size: $perspective-link-size;
                    height: 36px;
                    line-height: 36px;
                    padding: 0px 25px;
                    transition: color 0.5s ease;
                    color: #011F36;
                }

                .icon-perspective-close {
                    visibility: hidden;
                    opacity: 0;
                    transition: visibility 0s, opacity 0.5s linear;
                    cursor: pointer;
                    vertical-align: middle;
                    transition: all 0.5s ease;

                    path {
                        stroke-width: 20px;
                        stroke: #011f36;
                    }
                }
            }

            .item:hover {
                .icon-perspective-close {
                    visibility: visible;
                    opacity: 1;
                    transition: all 0.3s ease-in-out;
                }
            }
        }
    }
}
