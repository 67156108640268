.adp-desktop-grid-popup-container {
  background: #ffffff;
  box-shadow: #ccc 1px 1px 3px, #ccc -1px -1px 3px;
  flex-direction: column;
  z-index: 21;

  &:before {
    background: #7f7f7f;
    bottom: 0;
    content: "";
    left: 0;
    opacity: 0.25;
    position: fixed;
    right: 0;
    top: 0;
    z-index: -1;
  }
}

.adp-desktop-grid-popup-header {
  flex-direction: row-reverse;
  background-color: white;
  color: #666666;
  display: flex;
  font-size: 12px;

  justify-content: space-between;
  padding: 5px 5px 0 0;
}

.adp-desktop-grid-popup {
  background-color: white;
  color: #666666;
  font-size: 12px;
  overflow-y: auto;
  padding: 10px;

  .adp-desktop-grid-popup-item {
    margin-bottom: 5px;
  }
}

.grid-popup-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
  margin-top: -45px;
}

.json-popup-app-container {
  width: 60vw;
  height: 80vh;
  padding: 0 25px 0 25px;
}

.pdf-popup-app-container {
  height: 80vh;
  width: 50vw;
}
