.adp-desktop-grid-icon-container {
  display: flex;
  justify-content: space-around;
  padding: 5px;
  text-align: center;
  width: 100%;

  &-item-15 {
    display: inline-block;
    vertical-align: -webkit-baseline-middle;
    width: 15%;
  }

  &-item-20 {
    display: inline-block;
    vertical-align: -webkit-baseline-middle;
    width: 20%;
  }

  &-item-35 {
    display: inline-block;
    vertical-align: -webkit-baseline-middle;
    width: 35%;
  }

  &-circle {
    border-radius: 50%;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &-left-aligned {
    justify-content: flex-start;

    div {
      display: inline;
    }
  }
}
