.adp-grid-cell-menu-anchor {
  position: absolute;
  right: 0px;
  top: 4px;

  > svg {
    color: #777;
    cursor: pointer;
  }
}

.ag-grid-cell-menu-popup {
  position: absolute;
  font-family: $font-family-default;
}

.ag-grid-cell-menu {
  max-width: 420px;
  min-width: 200px;
  width: auto;
}

.ag-grid-actions-menu {
  overflow-x: hidden;
  overflow-y: auto;
}

.ag-grid-cell-menu-top-pane {
  display: flex;
  border: 0px !important;
  padding: 10px 2px !important;
  background-color: transparent !important;

  .rc-menu {
    border-style: none;
    box-shadow: none;
    padding: 0 !important;
    border: 0px !important;
    width: 100% !important;
  }

  .ag-grid-cell-comment-menu {
    padding: 0px !important;
    width: 240px !important;
  }
}

.ag-grid-cell-menu-popup-offscreen {
  top: -9999;
  left: -9999;
}

.ag-grid-cell-menu-arrow {
  position: absolute;
  z-index: 9998;
}

.ag-grid-cell-menu-arrow:after,
.ag-grid-cell-menu-arrow:before {
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-width: 10px;
}

.ag-grid-cell-menu-arrow-small:after,
.ag-grid-cell-menu-arrow-small:before {
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-width: 7px;
}

.ag-grid-cell-menu-arrow-left {
  left: 13px;
}

.ag-grid-cell-menu-arrow-left:after {
  border-right-color: white;
  margin-left: -30px;
}

.ag-grid-cell-menu-arrow-left:before {
  border-right-color: #ccc;
  margin-left: -32px;
}

.ag-grid-cell-menu-arrow-right {
  right: -29px;
}

.ag-grid-cell-menu-arrow-right:after {
  border-left-color: white;
  margin-left: -30px;
}

.ag-grid-cell-menu-arrow-right:before {
  border-left-color: #ccc;
  margin-left: -29px;
}

.ag-grid-cell-menu-arrow-bottom:after {
  border-top-color: white;
  margin-top: -2px;
}

.ag-grid-cell-menu-arrow-bottom:before {
  border-top-color: #ccc;
}

.menu-item-with-arrow {
  > svg {
    float: right;
  }
}
