.viewpoint-filter {
  border-top: solid 1px #eee;
  display: flex;
  flex-direction: row;
  font-size: 12px;
  margin: 5px 0px;
  padding-top: 4px;

  &-group {
    display: flex;
  }

  &-input {
    display: flex;
    align-items: center;
    margin-right: 10px;
  }

  &-separator {
    background-color: #eee;
    display: block;
    height: 28px;
    margin: 0px 5px;
    position: relative;
    top: 3px;
    width: 2px;
  }

  &-input-label {
    align-items: center;
    display: inline-block;
    line-height: 32px;
    padding: 3px 3px 0px 10px;
    white-space: nowrap;
    width: auto;
    font-size: 13px;
    margin-right: 5px;
  }

  &-input-filtering-select {
    .input-container {
      background-color: transparent;
      border-radius: 20px;
      border: solid 1px #ccc;
      min-width: 195px;

      .icon-reset {
          margin-top: 6px !important;
      }

      input {
        background-color: transparent;
        font-size: 12px !important;
        color: $primary-color;
        padding: 0px !important;
        margin: 7px !important;
      }
    }

    .list-container {
      border-radius: 10px;

      .option {
        font-size: 12px;
        padding: 8px 10px !important;
      }
    }

    &.disabled > .input-container > input {
        color: #999;
    }
  }

  &-input-date-finder {
      input {
          background-color: transparent;
          border-radius: 20px;
          border: solid 1px #ccc !important;
          color: $primary-color;
          font-size: 12px !important;
          height: 28px;
          outline-style: none;
          padding-left: 8px;
          padding-right: 8px;
          width: 138px;
      }
  }

  &-tag-container {
    display: flex;
  }

  &-tag {
    font-size: 14px;
    color: #ffffff;
    background-color: #999999;
    border-radius: 15px;
    padding: 7px 12px;
    margin: 3px;
  }
}

.viewpoint-filter-disabled {
    opacity: 0.2;
    pointer-events: none;
}
