.search-panel {
  text-align: right;
  position: absolute;
  top: 0px;
  right: 0px;
  opacity: 1;
  z-index: 2;

  .search-icon-wrapper {
    display: inline-block;
    border-radius: 20px;
    border: solid 1px #ccc;
    background-color: rgba(255,255,255,.5);
    box-shadow: 3px 3px 20px 0px rgba(99,99,99,0.25);
    margin: 15px 10px 5px 10px;
    padding: 7px 8px 6px 8px;
  }

  .search-bar {
    width: calc(100% - 59px);
    background-color: #fff;
    color: #fff;
    border: none;
    font-size: 16px;
    margin: 0;
    padding: 13px;
    outline: none;
  }
}

.search {
  width: 100%;
  text-align: left;

  .search-container {
    .search-header {
      box-shadow: none;
      position: relative;

      .search-header-wrapper {
        border-radius: 20px;
        border: solid 1px #ccc;
        padding: 0 10px;

        .search-input-wrapper {
          input {
            padding: 5px 8px 5px 0px;
            min-height: 20px;
            font-size: 13px;
            color: initial;
          }
        }
      }
    }

    .search-view-container {
      margin-top: 0px;
      width: 100%;
    }
  }
}
