.grid-history-main-column {
  background: rgba($grid-history-parent-color, 1) !important;
  border: none !important;
}

.grid-history-main-cell {
  background: rgba($grid-history-parent-color, 0.9) !important;
  border: none !important;
  border-bottom: 1px solid rgba($grid-history-border-color, 0.4) !important;
}

.grid-history-column-0,
.grid-history-column-1,
.grid-history-column-2,
.grid-history-cell-0,
.grid-history-cell-1,
.grid-history-cell-2 {
  border: none !important;
  text-align: right !important;
}

.grid-history-cell-0,
.grid-history-cell-1,
.grid-history-cell-2 {
  border-bottom: 1px solid rgba($grid-history-border-color, 0.4) !important;
  padding: 3px 25px !important;
}

.grid-history-column-0 {
  background: rgba($grid-historical-0-color, 1) !important;
}

.grid-history-cell-0 {
  background: rgba($grid-historical-0-color, 0.9) !important;
}

.grid-history-column-1 {
  background: rgba($grid-historical-1-color, 1) !important;
}

.grid-history-cell-1 {
  background: rgba($grid-historical-1-color, 0.9) !important;
}

.grid-history-column-2 {
  background: rgba($grid-historical-2-color, 1) !important;
}

.grid-history-cell-2 {
  background: rgba($grid-historical-2-color, 0.9) !important;
}

.grid-history-control-column {
  text-align: center !important;
  padding: 2px !important;
}
