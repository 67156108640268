@keyframes adp-e-desktop-loader-animation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.adp-e-desktop-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  svg {
    animation: adp-e-desktop-loader-animation 1s infinite linear;
  }
}
