.people-view {
    &-container {
        display: flex;
        flex-direction: column;
        height: 100%;

        .adp-e-mobile-content {
            display: flex;
            flex-direction: column;
            height: 100%;

            .adp-e-mobile-timeline-personcard-container {
                display: flex;
                flex-direction: column;
                height: 100%;

                .person-card-header {
                    border-bottom: 1px $lightest-gray solid;
                }
            }
        }
    }
}
