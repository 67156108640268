.errorRenderer {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 70%;
  padding: 100px 100px 0px 100px;
  overflow: scroll;

  > h2 {
    font-size: 20px;
    color: #FF5733;
  }
}
