.grid-toolbar {
    margin: 5px 0px;
    min-height: 35px;
    display: flex;
    justify-content: space-between;

    &-left {
        justify-content: flex-start;
    }

    &-right {
        justify-content: flex-end;
    }

    > div {
        display: flex;
        flex-wrap: wrap;

        > ul {
            margin: 0;
            padding: 0;
            display: flex;
            align-items: center;
            flex-flow: row nowrap;

            > .grid-toolbar-icons {
                display: inline-block;
            }

            .btn {
                border: none;
                color: $gray;
                cursor: pointer;
                font-family: $font-family-default;
                font-size: $grid-texts-size;
                height: 25px;
                margin-right: 10px;
                outline: none;
                padding: 0;
                text-transform: uppercase;
                width: 90px;
            }

            .btn.default {
                border-radius: 5px;
                background-color: #178bf5;
                color: white;
            }

            .btn.disabled {
                color: white;
                cursor: default;
            }
        }
    }
}

.grid-toolbar-additional-options {
    align-items: center;
    display: flex;
    float: right;
    height: 35px;
    padding: 5px 0 0;

    .btn {
        background: #ffffff;
        border: none;
        color: $gray;
        cursor: pointer;
        font-size: 11px;

        outline: none;
        padding: 0;
        text-transform: uppercase;
    }
}

.grid-toolbar-icons {
    list-style-type: none;

    i {
        display: block;
    }
}

.button-active .grid-toolbar-projection-icon {
    color: $grid-projection-icon;
}

.button-active .grid-toolbar-history-icon {
    color: $grid-history-icon;
}

.button-active .grid-toolbar-harmonization-icon {
    color: $grid-harmonization-icon;
}

.grid-toolbar-separator {
    background-color: #cccccc;
    height: 20px;
    width: 1px;
    margin-left: 7px;
    margin-right: 7px;
    margin-top: 3px;
}

.grid-toolbar-reset-to-standard {
    border-top: 1px #cccccc solid;
}

.grid-toolbar-requisitions-dropdown {
    width: 163px;
}

.toolbar-visualizations-dropdown {
    margin-right: 6px;
}

.edit-buttons {
    display: flex;
    align-items: baseline;

    &-fix-errors {
        color: $error;

        &-message {
            margin-left: 5px;
        }
    }
}
