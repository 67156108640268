.adp-e-badge-counter {
	position: relative;

	.counter {
		display: block;
		position: absolute;
		color: white;
		background-color: $primary-color;
		border-radius: 10px;
		width: 12px;
		font-size: 8px;
		text-align: center;
		height: 12px;
		padding-top: 1px;
	}
}
