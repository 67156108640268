$viewpoint-tab-nav-font-color: #ffffff;
$viewpoint-tab-nav-font-size: 12px;
$viewpoint-tab-nav-bg-color: #999999;
$viewpoint-tab-nav-menu-bg-color: #999999;
$viewpoint-tab-nav-menu-font-size: 12px;
$viewpoint-tab-nav-menu-item-bg-color: #858585;

.viewpoint-tab-nav-element {
  background-color: $viewpoint-tab-nav-bg-color;
  color: $viewpoint-tab-nav-font-color;
  font-size: $viewpoint-tab-nav-font-size;
  margin: 0px 0px 0px 8px;
  border-radius: 15px;
  line-height: 21px;
  display: inline-block;

  &-menu {
    position: absolute;
    width: 225px;
    height: auto;
    bottom: 56px;
    background-color: $viewpoint-tab-nav-menu-bg-color;
    border-radius: 15px;
    padding: 10px 0px;
    z-index: 2;

    .input-container {
      display: none;
    }

    .tree-container {
      border: none;
      font-size: $viewpoint-tab-nav-menu-font-size;

      ul {
        height: auto !important;

        > li {
          padding-top: 0px;
          padding-bottom: 0px;
          margin-top: 0px;
          margin-bottom: 0px;

          &-selected {
            background-color: $viewpoint-tab-nav-menu-item-bg-color;
          }

          &:hover {
            background-color: $viewpoint-tab-nav-menu-item-bg-color;
          }
        }
      }
    }
  }

  .viewpoint-name {
    cursor: pointer;
  }

  svg {
    cursor: pointer;
    margin: 0px 10px;
    position: relative;
    top: 1px;
  }
}
