.grid-row-num-cell {


  > svg {
    position: absolute;
    left: 0;
    top: 6px;
  }
}

.grid-row-num-cell-error {
  border-bottom: 1px solid #e84c4c;
}

.grid-row-num-cell-inserted {
  border-bottom: 1px solid #4cbee8;
}

.grid-row-num-cell-edited {
  border-bottom: 1px solid MediumSeaGreen;
}

.grid-pinned-row {
  font-weight: $font-weight-bold;
}

.grid-pinned-icon-gray {
  color: $grid-text-disabled;
}

.grid-row-num-cell-value {
  padding-top: 2px;
}
