.adp-container {
    display: flex;
    flex-flow: row wrap;
    box-sizing: border-box;
    overflow: hidden;
    transition: padding 0.3s ease;
    width: 100%;

    .adp-e-content-area {
        display: flex;
        width: 100%;
        height: calc(100vh - 45px);
        overflow: hidden;
    }
}

.impersonation-header {
    color: #ffffff;
    display: flex;
    height: 46px;
    padding-left: 15px;
    width: 100%;

    &.impersonation {
        background-color: #36a8ff;
    }

    &.view {
        background-color: #00cc33;
    }

    .user-details {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 90%;
    }

    .end-session-button {
        background-color: #ffffff;
        border-radius: 14px;
        color: #36a8ff;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        font-weight: bold;
        height: 60%;
        justify-content: center;
        margin: auto;
        padding: 0 30px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -khtml-user-select: none;
        -ms-user-select: none;
        font-weight: 500;
        min-inline-size: max-content;
    }
}

